/* Navbar */
.nav-item:hover .dropdown-menu {
  display: block !important;
  background-color: #212529;

}
.dropdown-item{
  color: rgba(255, 255, 255, 0.55) !important;
}
.dropdown-item:hover{
background-color: #FFC108 !important;
color:gray
}
.nav-item:hover{
  background-color: #FFC108;
  color: black;
  border-radius: 5px;
}
.active-sp{
  border-bottom: 2px solid #FFC108 !important;
  border-radius: 2px;
  
}

/* Slider */
.bg-slider{
  background-color: rgba(108,117,125,0.5);
}
@media (max-width: 576px ) {
   
  .bg-slider{
      margin-bottom: 0px;
      background-color: rgba(108,117,125,0.5);
  }
  .bg-slider h2{
      margin-bottom: 0.5rem;
      font-size: 1rem !important;
  }
  .bg-slider p{
   
    font-size: 0.75rem !important;
}
     
  }
  .mt-6{
    margin-top: 4.5rem;
   }
  @media (min-width: 577px ) {
      .sl{
          height: 582px !important;
       }
      .bg-slider{
          margin-bottom: 1.5rem;
          background-color: rgba(108,117,125,0.5);
      }
      .bg-slider h2{
          margin-bottom: 1.5rem;
      }
     
         
      }
      @media (min-width: 767px ) {
     
      
          .bg-slider a{
              padding: 0.5rem 1rem;
              font-size: 1.5rem;
              border-radius: 0.3rem;
          }
             
      }
      @media (min-width: 1024px ) {
     
          .sl{
             height: 650px !important;
          }
          .bg-slider a{
              padding: 0.5rem 1rem;
              font-size: 1.5rem;
              border-radius: 0.3rem;
          }
             
          }